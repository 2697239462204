import React, { useEffect, useRef, useState } from 'react';
import * as fabric from 'fabric'; // This is the correct import for Fabric.js v6
import Controls from './Controls';
import './MemeCanvas.css';
import bg1 from '../img/bg/bg-default.png';
import bg2 from '../img/bg/bg2.jpg';
import bg3 from '../img/bg/bg3.jpg';
import bg4 from '../img/bg/bg4.jpg';
import bg5 from '../img/bg/pompliano.png';
import bg6 from '../img/bg/ansem-boxing.jpg';
import bg7 from '../img/bg/solana-address.jpg';
import bg8 from '../img/bg/lockedin.jpg';
import bg9 from '../img/bg/wolf-wall-st-1.jpg';
import bg10 from '../img/bg/wolf-wall-st-2.webp';
import bg11 from '../img/bg/crypto-party.jpg';
import el1 from '../img/elements/el1.png';
import el2 from '../img/elements/el2.png';
import gunLeft from '../img/elements/gun-left.png';
import gunRight from '../img/elements/gun-right.png';
import dogPawLeft from '../img/elements/dogpaw-left.png';
import dogPawRight from '../img/elements/dogpaw-right.png';
import cashpile from '../img/elements/cashpile.png';
import solanaGoldCoin from '../img/elements/solana-gold-coin.png';
import dogNoHat from '../img/elements/dog-no-hat.png';

// Add your pre-defined image URLs here
const predefinedBackgrounds = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10, bg11];
const predefinedElements = [el1, el2, dogNoHat, gunLeft, gunRight, dogPawLeft, dogPawRight, cashpile, solanaGoldCoin,];

console.log("Predefined backgrounds:", predefinedBackgrounds);
console.log("Predefined elements:", predefinedElements);

function MemeCanvas() {
    const canvasRef = useRef(null);
    const fabricCanvasRef = useRef(null);
    const [text, setText] = useState('');
    const thumbnailsRef1 = useRef(null); // First row ref
    const thumbnailsRef2 = useRef(null); // Second row ref
    const [canScrollLeft1, setCanScrollLeft1] = useState(false);
    const [canScrollRight1, setCanScrollRight1] = useState(true);
    const [canScrollLeft2, setCanScrollLeft2] = useState(false);
    const [canScrollRight2, setCanScrollRight2] = useState(true);
    const [selectedObject, setSelectedObject] = useState(null);
    const [clickedObject, setClickedObject] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        console.log("Fabric.js version:", fabric.version);

        const canvas = new fabric.Canvas(canvasRef.current, {
            width: 500,
            height: 500,
            backgroundColor: 'white',
            preserveObjectStacking: true
        });
        fabricCanvasRef.current = canvas;

        console.log("Canvas dimensions:", canvas.width, "x", canvas.height);

        // Add watermark
        const watermarkText = new fabric.Text('dogwifmeme.xyz', {
            left: 0,
            top: canvas.height - 10,
            fontFamily: 'Arial',
            fontSize: 8,
            fill: 'white',
            backgroundColor: 'grey',
            padding: 3,
            selectable: false,
            evented: false
        });
        canvas.add(watermarkText);
        canvas.bringObjectToFront(watermarkText); // Bring watermark to front initially

        // Add observer to ensure watermark stays on top
        canvas.on('object:added', () => {
            canvas.bringObjectToFront(watermarkText);
        });

        // Add the first element to the canvas after the background is added
        addNewElementToCanvas(predefinedElements[0]);

        // Load the first background image
        handleImageSelect(predefinedBackgrounds[0]);

        // Reset scroll position for both thumbnail containers
        if (thumbnailsRef1.current) {
            thumbnailsRef1.current.scrollLeft = 0;
        }
        if (thumbnailsRef2.current) {
            thumbnailsRef2.current.scrollLeft = 0;
        }

        return () => {
            canvas.dispose();
        };
    }, []);

    const addText = (text, options = {}) => {
        const canvas = fabricCanvasRef.current;
        const uppercaseText = text.toUpperCase();
        const fabricText = new fabric.IText(uppercaseText, {
            left: canvas.width / 2,
            top: canvas.height - 10, // Position from top = canvas height minus margin
            fontFamily: 'Impact, Arial, sans-serif',
            fontSize: 40,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 2,
            textAlign: 'center',
            originX: 'center',
            originY: 'bottom', // Change to 'bottom' to align from bottom edge
            fontWeight: 'bold',
            ...options
        });

        fabricText.setControlsVisibility({
            ml: false,
            mt: false,
            mr: false,
            mb: false
        });

        // Remove any existing text objects
        canvas.getObjects('i-text').forEach(obj => canvas.remove(obj));

        // Add the new text to the canvas
        canvas.add(fabricText);

        canvas.setActiveObject(fabricText);
        canvas.renderAll();
    };

    const handleAddText = () => {
        if (text) {
            addText(text);
            setText('');
        }
    };

    const handleImageSelect = (imageUrl) => {
        console.log("handleImageSelect called with:", imageUrl);

        if (!fabricCanvasRef.current) {
            console.error("Canvas reference is not available");
            return;
        }

        const canvas = fabricCanvasRef.current;
        console.log("Current canvas objects:", canvas.getObjects());

        // Find the current background image object
        const backgroundImage = canvas.getObjects().find(obj => obj.type === 'image' && obj.selectable === true);

        // Create an HTML Image element
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = function () {
            console.log("Image loaded successfully", this);

            const imgAspect = img.width / img.height;
            const canvasAspect = canvas.width / canvas.height;

            let scaleFactor, left, top;

            if (imgAspect > canvasAspect) {
                // Image is wider than canvas
                scaleFactor = canvas.height / img.height;
                left = (canvas.width - img.width * scaleFactor) / 2;
                top = 0;
            } else {
                // Image is taller than canvas
                scaleFactor = canvas.width / img.width;
                left = 0;
                top = (canvas.height - img.height * scaleFactor) / 2;
            }

            const fabricImage = new fabric.Image(img, {
                scaleX: scaleFactor,
                scaleY: scaleFactor,
                left: left,
                top: top,
                originX: 'left',
                originY: 'top',
                selectable: true, // Make the background image selectable
                evented: true,
                lockUniScaling: true,
                lockScalingFlip: true,
                cornerSize: 13,
                hasControls: true,
            });

            // Remove middle controls on each side
            fabricImage.setControlsVisibility({
                ml: false,
                mt: false,
                mr: false,
                mb: false
            });

            if (backgroundImage) {
                // Replace the existing background image
                canvas.remove(backgroundImage);
            }

            // Add image to canvas
            canvas.add(fabricImage);
            // Ensure the background is always at the back
            canvas.sendObjectToBack(fabricImage);

            // Add event listener to send the background to back when selected
            fabricImage.on('selected', () => {
                canvas.sendObjectToBack(fabricImage);
            });



            canvas.renderAll();

            console.log("Image added to canvas. Current objects:", canvas.getObjects());
            console.log("Canvas rendered");
        };
        img.onerror = function (error) {
            console.error("Error loading image:", error);
        };
        img.src = imageUrl;
    };

    useEffect(() => {
        const updateScrollButtons1 = () => {
            if (thumbnailsRef1.current) {
                const { scrollLeft, scrollWidth, clientWidth } = thumbnailsRef1.current;
                setCanScrollLeft1(scrollLeft > 0);
                setCanScrollRight1(scrollLeft + clientWidth < scrollWidth);
            }
        };

        const updateScrollButtons2 = () => {
            if (thumbnailsRef2.current) {
                const { scrollLeft, scrollWidth, clientWidth } = thumbnailsRef2.current;
                setCanScrollLeft2(scrollLeft > 0);
                setCanScrollRight2(scrollLeft + clientWidth < scrollWidth);
            }
        };

        // Ensure refs are not null before adding event listeners
        if (thumbnailsRef1.current) {
            updateScrollButtons1();
            thumbnailsRef1.current.addEventListener('scroll', updateScrollButtons1);
        }

        if (thumbnailsRef2.current) {
            updateScrollButtons2();
            thumbnailsRef2.current.addEventListener('scroll', updateScrollButtons2);
        }

        return () => {
            if (thumbnailsRef1.current) {
                thumbnailsRef1.current.removeEventListener('scroll', updateScrollButtons1);
            }
            if (thumbnailsRef2.current) {
                thumbnailsRef2.current.removeEventListener('scroll', updateScrollButtons2);
            }
        };
    }, []);

    const scrollThumbnails = (direction, ref) => {
        if (ref.current) {
            const scrollAmount = 160;
            ref.current.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    function addNewElementToCanvas(imageUrl) {
        const canvas = fabricCanvasRef.current;
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = function () {
            let fabricImage;

            // Special handling for gun images
            if (imageUrl === gunLeft || imageUrl === gunRight) {
                const scaleFactor = 0.4; // Adjust this value to change gun size
                const isLeftGun = imageUrl === gunLeft;

                fabricImage = new fabric.Image(img, {
                    // Position on left or right edge, and align to bottom
                    left: isLeftGun ? 0 : canvas.width - (img.width * scaleFactor),
                    top: canvas.height - (img.height * scaleFactor) + 80, // Align to bottom
                    scaleX: scaleFactor,
                    scaleY: scaleFactor,
                    selectable: true,
                    evented: true,
                });
            } else if (imageUrl === dogPawLeft || imageUrl === dogPawRight) {
                const scaleFactor = 0.175; // Adjust this value to change dog paw size
                const isLeftPaw = imageUrl === dogPawLeft;

                fabricImage = new fabric.Image(img, {
                    // Position on left or right edge, and align to bottom
                    left: isLeftPaw ? -50 : canvas.width - (img.width * scaleFactor) + 65,
                    top: canvas.height - (img.height * scaleFactor) + 150, // Align to bottom
                    scaleX: scaleFactor,
                    scaleY: scaleFactor,
                    selectable: true,
                    evented: true,
                });
            } else {
                // Original handling for other elements
                const maxWidth = canvas.width * 0.7;
                const scaleFactor = Math.min(maxWidth / img.width, 1);

                fabricImage = new fabric.Image(img, {
                    left: (canvas.width - img.width * scaleFactor) / 2,
                    top: canvas.height - img.height * scaleFactor,
                    scaleX: scaleFactor,
                    scaleY: scaleFactor,
                    selectable: true,
                    evented: true,
                });
            }

            fabricImage.setControlsVisibility({
                ml: false,
                mt: false,
                mr: false,
                mb: false
            });

            canvas.add(fabricImage);
            canvas.bringObjectToFront(fabricImage);
            canvas.renderAll();
        };
        img.onerror = function (error) {
            console.error("Error loading image:", error);
        };
        img.src = imageUrl;
    }

    useEffect(() => {
        const canvas = fabricCanvasRef.current;

        // Event listener for object selection
        const onObjectSelected = (e) => {
            const activeObject = e.target;
            if (activeObject && activeObject.selectable) {
                setSelectedObject(activeObject);
                console.log("Object selected:", activeObject);
            } else {
                console.log("No selectable object was clicked.");
            }
        };

        // Event listener for selection cleared
        const onSelectionCleared = () => {
            setSelectedObject(null);
            console.log("Selection cleared");
        };

        // Event listener for object click
        const onObjectClicked = (e) => {
            const clickedObject = e.target;
            if (clickedObject) {
                console.log("Object clicked:", clickedObject);
                setClickedObject(clickedObject); // Update clickedObject state
                setSelectedObject(clickedObject); // Also update selectedObject if needed
            } else {
                console.log("No object clicked.");
                setClickedObject(null); // Reset clickedObject if no object is clicked
            }
        };

        // Attach event listeners
        canvas.on('selection:created', onObjectSelected);
        canvas.on('selection:updated', onObjectSelected);
        canvas.on('selection:cleared', onSelectionCleared);
        canvas.on('mouse:down', onObjectClicked);

        return () => {
            // Clean up event listeners
            canvas.off('selection:created', onObjectSelected);
            canvas.off('selection:updated', onObjectSelected);
            canvas.off('selection:cleared', onSelectionCleared);
            canvas.off('mouse:down', onObjectClicked);
        };
    }, []);

    const handleDelete = () => {
        console.log("Delete button pressed");
        console.log("Clicked object:", clickedObject);

        if (clickedObject && clickedObject.selectable) {
            fabricCanvasRef.current.remove(clickedObject);
            setClickedObject(null);
            fabricCanvasRef.current.discardActiveObject();
            fabricCanvasRef.current.renderAll();
            console.log("Object removed");
        } else {
            console.log("No selectable object to remove");
        }
    };


    const handleSendBackwards = () => {
        const canvas = fabricCanvasRef.current; // Get the canvas reference
        if (canvas) { // Ensure canvas is defined
            if (selectedObject) { // Check if selectedObject exists
                console.log("Sending object backwards:", selectedObject);
                console.log("Type of selectedObject:", selectedObject.type); // Log the type of the object

                // Check if the object is on the canvas before sending it backwards
                if (canvas.contains(selectedObject)) {
                    // Use sendObjectBackwards to move the object down in the stack
                    const intersecting = true; // Set to true if you want to move behind the first intersecting object
                    const moved = canvas.sendObjectBackwards(selectedObject, intersecting);

                    if (moved) {
                        canvas.renderAll(); // Re-render the canvas
                        console.log("Object sent backwards. Current order:", canvas.getObjects().map(obj => obj.id)); // Log object IDs or properties
                    } else {
                        console.log("No intersection found; object position unchanged.");
                    }
                } else {
                    console.log("Selected object is not on the canvas.");
                }
            } else {
                console.log("No object selected to send backwards.");
            }
        } else {
            console.log("Canvas is not defined.");
        }
    };


    const handleBringForward = () => {
        const canvas = fabricCanvasRef.current; // Get the canvas reference
        if (selectedObject) {
            console.log("Selected object:", selectedObject);
            console.log("Object type:", selectedObject.type); // Log the type of the object

            if (selectedObject instanceof fabric.Object) {
                console.log("Bringing object forward:", selectedObject);
                // Use bringObjectForward instead of bringForward
                const intersecting = true; // Set to true if you want to move in front of the first intersecting object
                const moved = canvas.bringObjectForward(selectedObject, intersecting); // Move the object forward

                if (moved) {
                    canvas.renderAll(); // Re-render the canvas
                    console.log("Object brought forward. Current order:", canvas.getObjects());
                } else {
                    console.log("No intersection found; object position unchanged.");
                }
            } else {
                console.log("Selected object is not a valid Fabric object.");
            }
        } else {
            console.log("No object selected to bring forward.");
        }
    };

    const handleUploadBackground = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            handleImageSelect(event.target.result); // Use the loaded image data
        };
        reader.readAsDataURL(file); // Read the file as a data URL
    };

    const handleUploadElement = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            addNewElementToCanvas(event.target.result); // Use the loaded image data
        };
        reader.readAsDataURL(file); // Read the file as a data URL
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const canvasContainer = document.querySelector('.canvas-container');
            const controlBar = document.querySelector('.control-bar');
            if (
                fabricCanvasRef.current &&
                !event.composedPath().includes(canvasContainer) &&
                !event.composedPath().includes(controlBar)
            ) {
                fabricCanvasRef.current.discardActiveObject();
                fabricCanvasRef.current.renderAll();
                setSelectedObject(null);
                setClickedObject(null);
                console.log("Selection cleared by clicking outside the canvas container and control bar");
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDownload = () => {
        const canvas = fabricCanvasRef.current;
        if (canvas) {
            // Create timestamp in YYYYMMDD-HHMM format
            const now = new Date();
            const timestamp = now.getFullYear().toString() +
                (now.getMonth() + 1).toString().padStart(2, '0') +
                now.getDate().toString().padStart(2, '0') + '' +
                now.getHours().toString().padStart(2, '0') +
                now.getMinutes().toString().padStart(2, '0');

            const dataURL = canvas.toDataURL({
                format: 'png',
                quality: 1,
                multiplier: 2,
                enableRetinaScaling: true
            });

            const link = document.createElement('a');
            link.download = `dogwifmeme_${timestamp}.png`; // Modified filename with timestamp
            link.href = dataURL;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleTouchStart = (e, ref) => {
        setIsDragging(true);
        setStartX(e.touches[0].pageX - ref.current.offsetLeft);
        setScrollLeft(ref.current.scrollLeft);
    };

    const handleTouchMove = (e, ref) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.touches[0].pageX - ref.current.offsetLeft;
        const walk = (x - startX) * 2;
        ref.current.scrollLeft = scrollLeft - walk;
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    return (
        <div className="meme-container">
            <h1>the wif maker</h1>
            <h3>make your own dogwifhat meme</h3>
            <div className="canvas-background">
                <canvas ref={canvasRef} className="meme-canvas"></canvas>
            </div>

            {/* Always render the control bar, but hide it when no object is selected */}
            <div className="control-bar" style={{ visibility: clickedObject ? 'visible' : 'hidden' }}>
                <button onClick={handleDelete}>Delete</button>
                <button onClick={handleSendBackwards}>Send Back</button>
                <button onClick={handleBringForward}>Bring Forward</button>
            </div>

            <h2>select background</h2>
            <div className="image-thumbnails-container">
                <button
                    className="arrow left"
                    onClick={() => scrollThumbnails('left', thumbnailsRef1)}
                    disabled={!canScrollLeft1}
                    style={{ opacity: canScrollLeft1 ? 1 : 0.5 }}
                >
                    ‹
                </button>
                <div
                    className="image-thumbnails"
                    ref={thumbnailsRef1}
                    onTouchStart={(e) => handleTouchStart(e, thumbnailsRef1)}
                    onTouchMove={(e) => handleTouchMove(e, thumbnailsRef1)}
                    onTouchEnd={handleTouchEnd}
                >
                    {predefinedBackgrounds.map((imageUrl, index) => (
                        <div
                            key={index}
                            className="thumbnail"
                            style={{
                                backgroundImage: `url(${imageUrl})`,
                                width: '150px',
                                height: '150px',
                                cursor: 'pointer',
                                margin: '5px'
                            }}
                            onClick={() => handleImageSelect(imageUrl)}
                        />
                    ))}
                </div>
                <button
                    className="arrow right"
                    onClick={() => scrollThumbnails('right', thumbnailsRef1)}
                    disabled={!canScrollRight1}
                    style={{ opacity: canScrollRight1 ? 1 : 0.5 }}
                >
                    ›
                </button>
            </div>

            <h2>select elements</h2>

            {/* Second row of thumbnails */}
            <div className="image-thumbnails-container">
                <button
                    className="arrow left"
                    onClick={() => scrollThumbnails('left', thumbnailsRef2)}
                    disabled={!canScrollLeft2}
                    style={{ opacity: canScrollLeft2 ? 1 : 0.5 }}
                >
                    ‹
                </button>
                <div
                    className="image-thumbnails"
                    ref={thumbnailsRef2}
                    onTouchStart={(e) => handleTouchStart(e, thumbnailsRef2)}
                    onTouchMove={(e) => handleTouchMove(e, thumbnailsRef2)}
                    onTouchEnd={handleTouchEnd}
                >
                    {predefinedElements.map((imageUrl, index) => (
                        <div
                            key={`new-${index}`}
                            className="thumbnail"
                            style={{
                                backgroundImage: `url(${imageUrl})`,
                                width: '150px',
                                height: '150px',
                                cursor: 'pointer',
                                margin: '5px'
                            }}
                            onClick={() => addNewElementToCanvas(imageUrl)}
                        />
                    ))}
                </div>
                <button
                    className="arrow right"
                    onClick={() => scrollThumbnails('right', thumbnailsRef2)}
                    disabled={!canScrollRight2}
                    style={{ opacity: canScrollRight2 ? 1 : 0.5 }}
                >
                    ›
                </button>
            </div>

            <h2>upload your own</h2>
            <Controls
                onUploadBackground={handleUploadBackground}
                onUploadElement={handleUploadElement}
                text={text}
                setText={setText}
                onAddText={handleAddText}
            />

            {/* Add download button */}
            <button
                className="download-button"
                onClick={handleDownload}
                style={{
                    fontSize: '1.2em',
                    padding: '15px 30px',
                    margin: '20px 0',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    width: '520px',
                    marginTop: '40px',
                    transition: 'all 0.3s ease', // Add smooth transition
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#45a049'} // Darker shade on hover
                onMouseOut={(e) => e.target.style.backgroundColor = '#4CAF50'} // Return to original color
            >
                Download meme
            </button>

            {/* Add footer note */}
            <div style={{
                fontSize: '0.7em',
                color: '#000',
                fontWeight: '500',
                textAlign: 'center',
                marginTop: '10px',
                marginBottom: '20px',
                width: '90%'
            }}>
                made wif <span style={{ fontSize: '0.7em', color: 'red' }}>❤️</span> by <a href="https://x.com/cryptogatsby" target="_blank" style={{ color: '#000', textDecoration: 'none' }} rel="noopener noreferrer">@cryptogatsby</a>. Donate some hats or SOL to support development: 4k8awhrxZGWEkrhUXLM2KC5qwA6pRJKwj2u4jvAuimBZ
            </div>

        </div>
    );
}

export default MemeCanvas;
