import React from 'react';
import './Controls.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

function Controls({ onUploadBackground, onUploadElement, text, setText, onAddText }) {
    const handleFileInput = (event) => {
        const file = event.target.files[0];
        if (file) {
            onUploadBackground(file);
        }
    };

    return (
        <>
            <div className="controls-container" style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                    type="file"
                    id="backgroundUpload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileInput}
                />
                <label htmlFor="backgroundUpload" className="control-button">
                    <FontAwesomeIcon icon={faUpload} style={{ marginRight: '8px' }} />
                    Upload Background
                </label>
                <input
                    type="file"
                    id="elementUpload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => onUploadElement(event.target.files[0])}
                />
                <label htmlFor="elementUpload" className="control-button">
                    <FontAwesomeIcon icon={faUpload} style={{ marginRight: '8px' }} />
                    Upload Element
                </label>
            </div>

            <h2>write something</h2>

            <div className="text-controls" style={{
                display: 'flex',
                marginTop: '10px',
                justifyContent: 'space-between',
                width: '60%'
            }}>
                <input
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Enter text"
                    style={{ flexGrow: 1, marginRight: '10px', borderRadius: '25px', border: '2px solid rgb(87, 50, 4)', padding: '10px' }}
                />
                <button onClick={onAddText} className="control-button">Add Text</button>
            </div>
        </>
    );
}

export default Controls;
