import React from 'react';
import MainContainer from './components/MainContainer';
import './App.css';

function App() {
  return (
    <>
      <div className="background-wrapper"></div>
      <div className="App">
        <MainContainer />
      </div>
    </>
  );
}

export default App;
