import React from 'react';
import MemeCanvas from './MemeCanvas';
import Controls from './Controls';
import './MainContainer.css';

function MainContainer() {
    return (
        <div className="main-container">
            <MemeCanvas />
        </div>
    );
}

export default MainContainer;
